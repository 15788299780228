<template>
  <div>
    <el-image
      fit="fill"
      style="width: 100%; height:290px"
      :src="bannerUrl"
    ></el-image>
    <div style="width:100%; height:68px; background:#475AFF">
      <div style="width:1320px; margin:0 auto; text-align:left">
        <ul>
          <li class="tabStyleLi">
            <div class="tabStyle" style="background:#3246F4"></div>
            <div
              class="tabStyle2"
              style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
            >
              <div>01</div>
              <div>公司新闻</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div
      style="width:1200px; margin:0 auto; height: 44px;box-shadow: 0px 1px 0px 0px rgba(71, 90, 255, 0.1);"
    >
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }"
          ><span style="color:#303133">首页</span></el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/components/web/news/index' }"
          ><span style="color:#303133">公司新闻</span></el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>

    <div style="width:1200px; margin:0 auto; ">
      <el-image fit="contain" :src="corporateVision1"></el-image>
    </div>
    <div style="margin-bottom:30px"></div>
  </div>
</template>

<script>
export default {
  name: 'case01',
  components: {},
  data() {
    return {
      bannerUrl: require('../../../assets/aboutus/top-banner.png'),
      corporateVision1: require('../../../assets/new01.png'),
      corporateVision2: require('../../../assets/aboutus/corporateVision-img2.png'),
      corporateVision3: require('../../../assets/aboutus/corporateVision-img3.png'),
    }
  },
  methods: {},
}
</script>

<style>
ul {
  margin: 0;
}
.tabStyle {
  color: #ffffff;
  display: inline-block;
  height: 68px;
  width: 170px;
}
.tabStyleLi {
  display: inline-block;
  height: 68px;
  position: relative;
}
.tabStyle:hover {
  background: #3246f4 !important;
  opacity: 0.7;
  height: 68px;
  cursor: pointer;
}

.el-image {
  overflow: visible !important;
}
.el-breadcrumb {
  line-height: 44px;
}
</style>
